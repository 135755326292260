import React from "react"
import styled from "styled-components"
import Typewriter from 'typewriter-effect';
import { Container } from "../global"
import HeaderImage from "/src/static/headerimage.svg"
import SignUp from "../common/forms/forms";

const Header = () => {  
  
  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>The Jadual App</Subtitle>
            <h1>
              Manage your team
              <br />
                <Typewriter 
                  onInit={(typewriter) => {
                    typewriter
                      .typeString('<strong><span style="color: #27ae60;">smarter</strong>')
                      .pauseFor(800)
                      .deleteChars(10)
                      .typeString('<strong><span style="color: #27ae60;">faster</strong>')
                      .pauseFor(800)  
                      .deleteChars(10)
                      .typeString('<strong><span style="color: #27ae60;">better</strong>')
                      .pauseFor(800)
                      .deleteChars(10)
                      .start()
                  }}
                    options={{
                      autoStart: true,
                      loop: true,
                  }}
                  // #27ae60
                />
            </h1>
            <h2>
              We're building the next generation team scheduling app. Sign up to join our private beta soon - it's free!
            </h2>
            <SignUp />
            {/* <FormSubtitle>
              Join our private beta for free.
            </FormSubtitle> */}
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage>
                  <HeaderImage />
            </StyledImage>
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  position: relative;
  padding: 250px 0 80px 0;

  @media (max-width: ${props => props.theme.screen.xs}
    ) {
      padding: 80px 0 80px 0;
    }

  @media (max-width: ${props => props.theme.screen.md}
    ) {
      padding: 120px 0 80px 0;
    }

  background: linear-gradient(360deg, rgba(0, 0, 0, 0.2) 9.42%, rgba(0, 0, 0, 0) 100%), linear-gradient(270deg, rgba(252, 252, 252, 0.69) 0%, rgba(252, 249, 255, 0.664844) 0.01%, rgba(219, 178, 255, 0) 96.01%), #673AB7;
  background-blend-mode: darken, normal, normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
`

  // background: linear-gradient(360deg, rgba(0, 0, 0, 0.2) 9.42%, rgba(0, 0, 0, 0) 100%), linear-gradient(270deg, rgba(252, 252, 252, 0.69) 0%, rgba(252, 249, 255, 0.664844) 0.01%, rgba(219, 178, 255, 0) 96.01%), #673AB7;
  // background-blend-mode: darken, normal, normal;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

// background: linear-gradient(360deg, rgba(0, 0, 0, 0.2) 9.42%, rgba(0, 0, 0, 0) 100%), linear-gradient(270deg, rgba(252, 252, 252, 0.69) 0%, rgba(252, 249, 255, 0.664844) 0.01%, rgba(219, 178, 255, 0) 96.01%), #A28ACC;
// background-blend-mode: darken, normal, normal;
// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);



const Subtitle = styled.h5`
  font-size: 20px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 16px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 16px;
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`


// const FormSubtitle = styled.span`
//   color: ${props => props.theme.color.accent};
//   ${props => props.theme.font_size.xxsmall}
// `

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled.div`
  width: 500px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`



// typewriter
//   .pauseFor(2500)
//   .typeString('A simple yet powerful native javascript')
//   .pauseFor(300)
//   .deleteChars(10)
//   .typeString('<strong>JS</strong> plugin for a cool typewriter effect and ')
//   .typeString('<strong>only <span style="color: #27ae60;">5kb</span> Gzipped!</strong>')
//   .pauseFor(1000)
//   .start();
