import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <SectionTitle>Automated scheduling for your team</SectionTitle>
      <FeaturesGrid>

        <FeatureItem>
          <FeatureTitle>Automate your work</FeatureTitle>
          <FeatureText>
            Let AI take over the repetitive and time-consuming work. Focus on your team.
          </FeatureText>
        </FeatureItem>

        <FeatureItem>
          <FeatureTitle>Schedule Faster</FeatureTitle>
          <FeatureText>
            Our AI algorithm allows you to generate your team's working schedule within a fraction of time. Take control of your time. Save countless hours.
          </FeatureText>
        </FeatureItem>

        <FeatureItem>
          <FeatureTitle>Swap/Trade Shifts</FeatureTitle>
          <FeatureText>
            Swap or trade your shifts with your colleagues with the click of a button.
          </FeatureText>
        </FeatureItem>
        
        <FeatureItem>
          <FeatureTitle>Submit Requests</FeatureTitle>
          <FeatureText>
            Put in all your shift requests and leaves with the convenience of your mobile phone or your browser.
          </FeatureText>
        </FeatureItem>

        <FeatureItem>
          <FeatureTitle>Schedule Reports</FeatureTitle>
          <FeatureText>Summarize your team's shifts information with a click of a button</FeatureText>
        </FeatureItem>

        <FeatureItem>
          <FeatureTitle>Intuitive Scheduling</FeatureTitle>
          <FeatureText>
            Escape spreadsheet hell with Jadual’s intuitive drag-and-drop interface
          </FeatureText>
        </FeatureItem>

        <FeatureItem>
          <FeatureTitle>Personalized</FeatureTitle>
          <FeatureText>
            The Jadual App enables you to take control of your work-life balance. Your own personalized schedule, within your team's roster
          </FeatureText>
        </FeatureItem>

        <FeatureItem>
          <FeatureTitle>Receive Notifications</FeatureTitle>
          <FeatureText>
            Receive notification for newly released team shift schedule
          </FeatureText>
        </FeatureItem>
        
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
