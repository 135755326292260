import React from 'react';
import styled from "styled-components";
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Formik } from 'formik';



 const SignUp = () => (
     <Formik
       initialValues={{email: ''}}
       
       validate={values => {
        const errors = {};
         if (!values.email) {
           errors.email = 'Email Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         return errors;
       }}
       onSubmit={(values, { setSubmitting }) => {

        addToMailchimp(values.email)
        .then(data => {
            
            var span = document.getElementById('mailchimp-response');
            var format = /[<?>]/;
            var success = 'Thank you for subscribing!'
            if (format.test(data.msg)){
                const response = data.msg
                var parsed = response.substring(0, response.indexOf(' to list Jadual app.')) + `. Share Jadual App with your team leader!`
                while( span.firstChild ) {
                    span.removeChild( span.firstChild );
                }
                span.appendChild( document.createTextNode(parsed));    
            }
            else if (data.msg === success) {
                var parsedSuccess = `Thank you for subcribing! Help us share this with your team leader.`
                while( span.firstChild ) {
                    span.removeChild( span.firstChild );
                }
                span.appendChild( document.createTextNode(parsedSuccess));
            }
            else {
                var parsedSuccess_else = data.msg
                while( span.firstChild ) {
                    span.removeChild( span.firstChild );
                }
                span.appendChild( document.createTextNode(parsedSuccess_else));    
            }
        })
        setTimeout(() => {
           setSubmitting(false);
           
         }, 400);
        }}
     >
       {({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting,
        }) => (
            <form onSubmit={handleSubmit}>
                <HeaderDiv>
                
                <HeaderInput
                placeholder="Type your email here"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                />            
                <HeaderButton type="submit" disabled={isSubmitting}>
                Submit
                </HeaderButton>
                
                </HeaderDiv>
                <br/>
                <FormError>
                {errors.email && touched.email && errors.email }
                </FormError>
                <MailchimpResponse id="mailchimp-response"></MailchimpResponse>
            </form>
        )}
      </Formik>
 );
 
 export default SignUp;

 const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

 const HeaderInput = styled.input`
 font-weight: 500;
 font-size: 16px;
 color: ${props => props.theme.color.primary};
 line-height: 42px;
 width: 100%;
 text-align: left;
 height: 60px;
 border-width: 1px;
 border-style: solid;
 border-color: ${props => props.theme.color.secondary};
 border-image: initial;
 border-radius: 4px;
 padding: 8px 16px;
 outline: 0px;
 &:focus {
   box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
 }
 @media (max-width: ${props => props.theme.screen.md}) {
   margin-bottom: 8px;
 }
 @media (max-width: ${props => props.theme.screen.sm}) {
   display: block;
   width: 100%;
 }
`

const FormError = styled.span`
  font-size: 6px;
  color: ${props => props.theme.color.pop};
  ${props => props.theme.font_size.xsmall}
`

const MailchimpResponse = styled.span`
  font-size: 6px;
  color: ${props => props.theme.color.accent};
  ${props => props.theme.font_size.xsmall}
`

 const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(39, 174, 96) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`