import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import styled from "styled-components"
import ShareLinks from "../common/forms/share"


import { Container, Section } from "../global"

const GetStarted = () => (
  <StyledSection>
    <GetStartedContainer>
      <GetStartedTitle>Be the first to join the beta
        <br/>
        Let your team leader know!</GetStartedTitle>
      <ShareLinks />
      <Scrollspy componentTag={GetStartedDiv} item={["top"]} currentClassName="active">
        <AnchorLink href="#top">
          <HeaderButton>
          Get early access
          </HeaderButton>
        </AnchorLink>        
      </Scrollspy>
      
      <Subtitle>Beta access is free. No debit/credit card is required.</Subtitle>
    </GetStartedContainer>
  </StyledSection>
)

export default GetStarted


const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const GetStartedDiv = styled.div`
  align-items: center;
  text-decoration: none !important;
  list-style-type: none;
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 0px;
  text-transform: uppercase;
  text-decoration: none
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(39, 174, 96) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const Subtitle = styled.span`
  ${props => props.theme.font_size.xxsmall}
  padding-top: 16px;
  font-size: 14px;
  color: ${props => props.theme.color.primary};
`
