import React from 'react';
import styled from 'styled-components';
import { 
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailIcon,
    EmailShareButton,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    RedditShareButton,
    RedditIcon
} from 'react-share';

var shareUrl = 'http://jadualapp.com'
var title = 'The Jadual App - The next generation team scheduling app. Manage your team smarter, faster, better.';
var size = 50

const ShareLinks = () => {
    return (
    
    <SocialShare>
         <Icons>
            <FacebookShareButton
                url={shareUrl}
                quote={title}
            >
                <FacebookIcon size={size} round />
            </FacebookShareButton>
         </Icons>

         <Icons>
            <WhatsappShareButton
                url={shareUrl}
                quote={title}
            >
                <WhatsappIcon size={size} round />
            </WhatsappShareButton>
         </Icons>

         <Icons>
            <LinkedinShareButton
                url={shareUrl}
                quote={title}
            >
                <LinkedinIcon size={size} round />
            </LinkedinShareButton>
         </Icons>

         <Icons>
            <EmailShareButton
                url={shareUrl}
                quote={title}
            >
                <EmailIcon size={size} round />
            </EmailShareButton>
         </Icons>

         <Icons>
            <TwitterShareButton
                url={shareUrl}
                quote={title}
            >
                <TwitterIcon size={size} round />
            </TwitterShareButton>
         </Icons>

    </SocialShare>
    )
};

export default ShareLinks;

const SocialShare = styled.span`
    vertical-align: top;
    display: inline-block;
    margin-right: 0px;
    text-align: center;
    padding: 16px;
`

const Icons = styled.div`
    display: inline-block;
    margin-right: 16px;
`